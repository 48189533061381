import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { WMTLandingIterState, WMTLandingState } from './types';
import * as treekeys from './treekeys';

const initState: WMTLandingIterState = {
  showValidateErrorsAccountNumber: false,
  showValidateErrorsOrderOrRepairNumber: false,
  isOrderOrRepairNumber: false,
  selectedAccountNumber: '',
  selectedOrderOrRepairNumber: '',
  selectedOrderOrRepairNumberState: '',
  selectedZipcode: '',
  throttled: false,
  submitPending: false,
  queryParamsUsed: false,
  hideResults: false,
};

export const data: WMTLandingState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<WMTLandingState, any> = {
  [treekeys.GET_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER]: (state) =>
    state.currState.isOrderOrRepairNumber,
  [treekeys.GET_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER]: (state) =>
    state.currState.selectedAccountNumber,
  [treekeys.GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER]: (state) =>
    state.currState.selectedOrderOrRepairNumber,
  [treekeys.GET_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE]: (state) =>
    state.currState.selectedOrderOrRepairNumberState,
  [treekeys.GET_WMTLANDINGMODULE_SELECTEDZIPCODE]: (state) =>
    state.currState.selectedZipcode,
  [treekeys.GET_WMTLANDINGMODULE_SELECTEDTECHID]: (state) =>
    state.currState.selectedTechId,
  [treekeys.GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER]: (state) =>
    state.currState.showValidateErrorsAccountNumber,
  [treekeys.GET_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER]: (
    state
  ) => state.currState.showValidateErrorsOrderOrRepairNumber,
  [treekeys.GET_WMTLANDINGMODULE_THROTTLED]: (state) =>
    state.currState.throttled,
  [treekeys.GET_WMTLANDINGMODULE_SUBMITPENDING]: (state) =>
    state.currState.submitPending,
  [treekeys.GET_WMTLANDINGMODULE_QUERYPARAMSUSED]: (state) =>
    state.currState.queryParamsUsed,
  [treekeys.GET_WMTLANDINGMODULE_HIDERESULTS]: (state) =>
    state.currState.hideResults,
  [treekeys.GET_WMTLANDINGMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_WMTLANDINGMODULE_PREVSTATE]: (state) => state.prevState,
};
export const mutations: MutationTree<WMTLandingState> = {
  [treekeys.MUTATE_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER]: (
    state,
    { isOrderOrRepairNumber }
  ) => {
    state.currState.isOrderOrRepairNumber = isOrderOrRepairNumber;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER]: (
    state,
    { selectedAccountNumber }
  ) => {
    state.currState.selectedAccountNumber = selectedAccountNumber;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER]: (
    state,
    { selectedOrderOrRepairNumber }
  ) => {
    state.currState.selectedOrderOrRepairNumber = selectedOrderOrRepairNumber;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE]: (
    state,
    { selectedOrderOrRepairNumberState }
  ) => {
    state.currState.selectedOrderOrRepairNumberState =
      selectedOrderOrRepairNumberState;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDZIPCODE]: (
    state,
    { selectedZipcode }
  ) => {
    state.currState.selectedZipcode = selectedZipcode;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDTECHID]: (
    state,
    { selectedTechId }
  ) => {
    state.currState.selectedTechId = selectedTechId;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER]: (
    state,
    { showValidateErrorsAccountNumber }
  ) => {
    state.currState.showValidateErrorsAccountNumber =
      showValidateErrorsAccountNumber;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER]: (
    state,
    { showValidateErrorsOrderOrRepairNumber }
  ) => {
    state.currState.showValidateErrorsOrderOrRepairNumber =
      showValidateErrorsOrderOrRepairNumber;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_THROTTLED]: (state, { throttled }) => {
    state.currState.throttled = throttled;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_SUBMITPENDING]: (
    state,
    { submitPending }
  ) => {
    state.currState.submitPending = submitPending;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_QUERYPARAMSUSED]: (
    state,
    { queryParamsUsed }
  ) => {
    state.currState.queryParamsUsed = queryParamsUsed;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_HIDERESULTS]: (state, { hideResults }) => {
    state.currState.hideResults = hideResults;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_WMTLANDINGMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
};
export const actions: ActionTree<WMTLandingState, any> = {
  [treekeys.ACTION_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER]: (
    { commit },
    { isOrderOrRepairNumber }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER, {
      isOrderOrRepairNumber,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER]: (
    { commit },
    { selectedAccountNumber }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER, {
      selectedAccountNumber,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER]: (
    { commit },
    { selectedOrderOrRepairNumber }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBER, {
      selectedOrderOrRepairNumber,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE]: (
    { commit },
    { selectedOrderOrRepairNumberState }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDORDERORREPAIRNUMBERSTATE, {
      selectedOrderOrRepairNumberState,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SELECTEDZIPCODE]: (
    { commit },
    { selectedZipcode }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDZIPCODE, {
      selectedZipcode,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SELECTEDTECHID]: (
    { commit },
    { selectedTechId }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_SELECTEDTECHID, {
      selectedTechId,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER]: (
    { commit },
    { showValidateErrorsAccountNumber }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER, {
      showValidateErrorsAccountNumber,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER]: (
    { commit },
    { showValidateErrorsOrderOrRepairNumber }
  ) => {
    commit(
      treekeys.MUTATE_WMTLANDINGMODULE_SHOWVALIDATEERRORSORDERORREPAIRNUMBER,
      { showValidateErrorsOrderOrRepairNumber }
    );
  },
  [treekeys.ACTION_WMTLANDINGMODULE_THROTTLED]: ({ commit }, { throttled }) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_THROTTLED, { throttled });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_SUBMITPENDING]: (
    { commit },
    { submitPending }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_SUBMITPENDING, { submitPending });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_QUERYPARAMSUSED]: (
    { commit },
    { queryParamsUsed }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_QUERYPARAMSUSED, {
      queryParamsUsed,
    });
  },
  [treekeys.ACTION_WMTLANDINGMODULE_HIDERESULTS]: (
    { commit },
    { hideResults }
  ) => {
    commit(treekeys.MUTATE_WMTLANDINGMODULE_HIDERESULTS, {
      hideResults,
    });
  },
};

const wmtLandingModule: Module<WMTLandingState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default wmtLandingModule;
