export interface CbrInfo {
  canBeReachedTN: string;
}

export interface AppointmentInfo {
  estimatedStartTime: string;
  estimatedCompletionTime: string;
  clickStatusValue?: string;
  startDateTime?: string;
  endDateTime?: string;
  techDispatch: boolean;
  techInstall: boolean;
  isDispatchInfoEmpty: boolean;
}

export interface TroubleReport {
  nativeTroubleReportIdentifier?: string;
  troubleReportStatus?: string;
  sourceSystem?: string;
  customerProductId?: string;
  productName?: string;
  cbrInfo?: CbrInfo;
  canCancel?: boolean;
  canReschedule?: boolean;
  appointmentInfo?: AppointmentInfo;
  originatingCrmSessionIdentifier?: string;
  createDateTime?: string;
  commitmentDateTime?: string;
  isPast?: boolean;
  isUpcoming?: boolean;
  showTechLocation?: boolean;
}

export interface RXTicketInfo {
  wtn: string;
  troubleReports: TroubleReport[];
  companyOwnerId: string;
}

export interface SOIAppointmentInfo {
  estStartTime: string;
  estCompletionTime: string;
  techLoadJobStatus: string;
  startDateTime?: string;
  endDateTime?: string;
  techDispatch?: boolean;
  techInstall?: boolean;
  isDispatchInfoEmpty?: boolean;
  estimatedArrivalWindowStart?: string;
  estimatedArrivalWindowEnd?: string;
  jobStatus?: string;
}

export interface ServiceOrderInfo {
  appointmentInfo?: SOIAppointmentInfo;
  serviceOrderNumber: string;
  serviceOrderStatus: string;
  orderType: string;
  dueDate: string;
  isPast?: boolean;
  isUpcoming?: boolean;
}

export interface CPEShipments {
  trackingNumber?: string;
  shipMethod?: string;
  deliveryLocation?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  estimatedDeliveryDate?: string;
  deliveredDate?: string;
}

export interface Dispatches {
  appointmentMilestone?: string;
  appointmentDate?: string;
  appointmentStartDate?: string;
  appointmentEndDate?: string;
  timeZone?: string;
  estimatedStartTime?: string;
  estimatedFinishTime?: string;
}

export interface SOIProducts {
  productName: string;
}

export interface CombinedServiceOrderInfo {
  appointmentInfo?: SOIAppointmentInfo | undefined;
  serviceOrderInfo: ServiceOrderInfo | undefined;
  cpeShipments?: CPEShipments[] | undefined;
  dispatches: Dispatches | Dispatches[];
  products: SOIProducts | undefined;
}

export interface OutageInfoResponse {
  showOutage?: boolean;
  pastOutage: boolean;
  resolutionDate: string;
}
export interface BSWInfo {
  showBswInfo: boolean;
  description: string[] | undefined;

  showChatWithUs: boolean;
}

export interface CombinedC360PendingOrdersAndRXTicketInfos {
  ticketInfos: RXTicketInfo[] | undefined;
  combinedServiceOrderInfos: CombinedServiceOrderInfo[] | undefined;
  outageInfoResponse: OutageInfoResponse | undefined;
  rxSessionIdentifier: string;
  useRXWS: boolean;
  postalCode: string;
  companyOwnerId: string;
  bswInfo: BSWInfo | undefined;
}

export interface C360PendingOrdersAndRXTicketInfos {
  accountNumber?: string;
  state?: string;
  orderNumberOrTicketNumber?: string;
  combinedC360AndRXResponse?: CombinedC360PendingOrdersAndRXTicketInfos;
}

export interface GetC360PendingOrdersAndRXTicketInfosState {
  pending: boolean;
  c360PendingOrdersAndRXTicketInfos: C360PendingOrdersAndRXTicketInfos;
  c360PendingOrdersAndRXTicketInfosError: Record<string, unknown> | undefined;
  brightSpeedNotification?: boolean;
}

export interface GetC360PendingOrdersAndRXTicketInfosParallelMap {
  [index: string]: GetC360PendingOrdersAndRXTicketInfosState;
}

export interface GetC360PendingOrdersAndRXTicketInfosParallelState {
  getC360PendingOrdersAndRXTicketInfosParallelMap: GetC360PendingOrdersAndRXTicketInfosParallelMap;
  lruKeyArr: string[];
  shouldThrottle: boolean;
  shouldShiftLRU: boolean;
}

export interface RXTicketInfoNormalized
  extends CombinedC360PendingOrdersAndRXTicketInfos,
    RXTicketInfo,
    TroubleReport {
  nativeTicketNum?: string;
  nativeTroubleTicketNumber?: string;
  customerProductID?: string; // case-insensitive customerProductID field here
  ticketNum?: string;
  sessionId?: string;
}

export interface Products {
  productType?: string;
  productTypeDesc?: string;
  productDescription?: string;
  accountNumber?: string;
  productId?: string;
  productName?: string;
  downloadSpeed?: string;
  uploadSpeed?: string;
  previousDownloadSpeed?: string;
  previousUploadSpeed?: string;
}

export interface Orders {
  orderNumber?: string;
  orderStatus?: string;
  orderType?: string;
  orderCreatedDate?: string;
  eulaStatus?: boolean;
  orderCommittedDueDate?: string;
  orderCancelDate?: string;
  orderCompletedDate?: string;
  isOrderDelayed?: boolean;
  reasonForDelay?: string;
  equipmentMilestone?: string;
  orderDueDate?: string;
  orderDueDateTimeZone?: string;
  techDispatch?: boolean;
  dispatches?: Dispatches[];
  cpeShipments?: CPEShipments[];
  products?: Products[];
  requiresCutomerAtHome?: boolean;
  normalizedOrderStatus?: string;
}

export interface C360PendingOrders {
  completedOrders?: Orders[];
  cancelledOrders?: Orders[];
  inflightOrders?: Orders[];
}

export interface GetC360PendingOrdersProgressIndicators {
  progressBarAppear?: boolean;
  progressValue?: number;
  longerThanUsual?: boolean;
}

export interface RXAppointmentInfo {
  appointmentId?: string;
  startDate?: string;
  endDate?: string;
  commitmentTime?: string;
}

export interface RXTicketInfos {
  appointments?: RXAppointmentInfo[];
}

export interface GetRXTicketInfosProgressIndicators {
  progressBarAppear?: boolean;
  progressValue?: number;
  longerThanUsual?: boolean;
}

export enum TicketOrderStatus {
  UPCOMING,
  PAST,
  COMPLETED,
  CANCELLED,
  UNKNOWN,
}
