class ErrorUtilsSingleton {
  isNetworkError = (obj: any) =>
    obj?.toString()?.includes('Error: Network Error') ||
    obj?.toString()?.includes('Error: timeout') ||
    obj?.toString()?.includes('Error: Request failed with status code');

  getTrackerTechStatusErrorMsg = (err: any) => {
    let errorMessage = '';
    if (!err) {
      errorMessage =
        'An error occured while processing the technician location request.';
    } else if (
      err?.name?.includes('AxiosError') ||
      err?.response?.status === 401
    ) {
      errorMessage =
        'The credentials you signed in with do not match the account that you are trying to view details for.';
    } else {
      errorMessage = err;
    }
    return errorMessage;
  };

  isRxProductInfoError = (obj: any) =>
    obj?.data &&
    obj.data?.rxSessionIdentifier === null &&
    obj.data?.accountInformation === null &&
    obj.data?.serviceLines === null &&
    obj.data?.apiResponse === null;
}

let errorUtilsSingleton: ErrorUtilsSingleton;

export const useErrorUtilsSingleton = (): ErrorUtilsSingleton => {
  if (!errorUtilsSingleton) {
    errorUtilsSingleton = new ErrorUtilsSingleton();
  }
  return errorUtilsSingleton;
};
