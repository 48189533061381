/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import * as tk from '../modules/trackerTechModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import { getServiceAddressAndTechnicianInfos as getServiceAddressAndTechnicianInfosApi } from './api/getServiceAddressAndTechnicianInfos';
import { useErrorUtilsSingleton } from '../../common/utils/errorUtilsSingleton';

export function* getServiceAddressAndTechnicianDetails(
  getServiceAddressTechDetailsPayLoad: any
): any {
  const trackerInfo: string =
    getServiceAddressTechDetailsPayLoad?.payload?.trackerInfo;
  const techId: string = getServiceAddressTechDetailsPayLoad?.payload?.techId;

  yield put({
    type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: true,
  });
  yield put({
    type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR,
    errorMessage: '',
  });
  yield put({
    type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHPENDING,
  });
  try {
    const resp = yield call(
      getServiceAddressAndTechnicianInfosApi,
      trackerInfo,
      techId
    );
    if (resp?.data?.status === 'SUCCESS') {
      const storedAddress: string = yield select(
        (s) =>
          s?.[tk.TRACKERTECHMODULE]?.trackerTechDetails?.serviceAddressDetails
            ?.address
      );
      if (resp.data?.serviceAddressDetails?.address === storedAddress) {
        yield put({
          type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION,
          technicianLocation: resp.data.technicianLocation,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH,
          trackerTechDetails: resp.data,
        });
      }
    } else {
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS,
      });
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR,
        errorMessage: useErrorUtilsSingleton().getTrackerTechStatusErrorMsg(
          resp?.data?.message
        ),
      });
    }
  } catch (err) {
    yield put({
      type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS,
    });
    yield put({
      type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR,
      errorMessage: useErrorUtilsSingleton().getTrackerTechStatusErrorMsg(err),
    });
  } finally {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: false,
    });
    if (yield cancelled()) {
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS,
      });
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR,
        errorMessage: 'SAGA_CANCELLED',
      });
    }
  }
}

export function* watchGetServiceAddressAndTechnicianDetails(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getServiceAddressAndTechnicianDetails);
}

export default watchGetServiceAddressAndTechnicianDetails;
